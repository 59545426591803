import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import ProsCons from "./ProsCons";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "state-cross-linking"
    }}>{`State Cross Linking`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={ProsCons} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<ProsCons>\n  <div type=\"pros\">\n    <span>Lorem ipsum dolor sit amet</span>\n    <span>Lorem ipsum dolor sit amet, consectetur</span>\n    <span>Lorem ipsum dolor</span>\n  </div>\n  <div type=\"cons\">\n    <span>Lorem ipsum dolor sit amet</span>\n    <span>Lorem ipsum dolor sit amet, consectetur</span>\n    <span>Lorem ipsum dolor</span>\n  </div>\n</ProsCons>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ProsCons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProsCons mdxType="ProsCons">
    <div type="pros">
      <span>Lorem ipsum dolor sit amet</span>
      <span>Lorem ipsum dolor sit amet, consectetur</span>
      <span>Lorem ipsum dolor</span>
    </div>
    <div type="cons">
      <span>Lorem ipsum dolor sit amet</span>
      <span>Lorem ipsum dolor sit amet, consectetur</span>
      <span>Lorem ipsum dolor</span>
    </div>
  </ProsCons>
    </Playground>
    <h2 {...{
      "id": "basic-usage-with-background-colors"
    }}>{`Basic Usage With Background Colors`}</h2>
    <p>{`Only hexadecimal color values are supported.`}</p>
    <Playground __position={2} __code={'<ProsCons>\n  <div type=\"pros\" backgroundColor=\"#339966\">\n    <span>Lorem ipsum dolor sit amet</span>\n    <span>Lorem ipsum dolor sit amet, consectetur</span>\n    <span>Lorem ipsum dolor</span>\n  </div>\n  <div type=\"cons\" backgroundColor=\"#cc0000\">\n    <span>Lorem ipsum dolor sit amet</span>\n    <span>Lorem ipsum dolor sit amet, consectetur</span>\n    <span>Lorem ipsum dolor</span>\n  </div>\n</ProsCons>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ProsCons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProsCons mdxType="ProsCons">
    <div type="pros" backgroundColor="#339966">
      <span>Lorem ipsum dolor sit amet</span>
      <span>Lorem ipsum dolor sit amet, consectetur</span>
      <span>Lorem ipsum dolor</span>
    </div>
    <div type="cons" backgroundColor="#cc0000">
      <span>Lorem ipsum dolor sit amet</span>
      <span>Lorem ipsum dolor sit amet, consectetur</span>
      <span>Lorem ipsum dolor</span>
    </div>
  </ProsCons>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      